<template>
  <div v-if="showFrame" class="iframe-wrapper">
    <iframe class="iframe" :src="loginPlayerData.loginUrl" />
    <div class="iframe-btns">
      <AntButton class="btn" type="primary" @click="onClickOpenFrame">
        <template #icon>
          <BackIcon />
        </template>
        Back
      </AntButton>
      <AntButton class="btn" type="primary" @click="onClickReload">
        <template #icon>
          <ReloadIcon />
        </template>
        Re-load
      </AntButton>
    </div>
  </div>

  <AntRow v-else class="row">
    <AntCol
      :sm="{ span: 24, offset: 0 }"
      :md="{ span: 20, offset: 2 }"
      :lg="{ span: 16, offset: 4 }"
    >
      <a class="link" target="_blank" :href="loginPlayerData.loginUrl">{{ loginPlayerData.loginUrl }}</a>

      <AntRow justify="center">
          <AntTooltip placement="top" title="URL copied successfully!" trigger="click">
            <AntButton class="btn" type="primary" @click="onClickCopy">
              <template #icon>
                <CopyIcon />
              </template>
              Copy URL
            </AntButton>
          </AntTooltip>

        <AntButton class="btn" type="primary" target="_blank" :href="loginPlayerData.loginUrl">
          <template #icon>
            <NewWindowIcon :rotate="90" />
          </template>
          Open in New Tab
        </AntButton>

        <AntButton class="btn" type="primary" @click="onClickOpenFrame">
          <template #icon>
            <FrameIcon />
          </template>
          Open in Frame
        </AntButton>

        <AntButton class="btn" type="primary" @click="onClickReload">
          <template #icon>
            <ReloadIcon />
          </template>
          Re-generate
        </AntButton>

        <AntButton class="btn" color="black" @click="onClickLogout">
          <template #icon>
            <LogoutIcon />
          </template>
          Player Logout
        </AntButton>
      </AntRow>

      <AntAlert
        :style="{ marginTop: '32px' }"
        v-if="!!alertState.message"
        :type="alertState.type"
        :message="alertState.message"
        showIcon
        @close="() => {}"
      />
    </AntCol>
  </AntRow>

</template>

<script>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'

import {
  Row as AntRow,
  Col as AntCol,
  Button as AntButton,
  Alert as AntAlert,
  Tooltip as AntTooltip,
  message as antMessage,
} from 'ant-design-vue'

import CopyIcon from '@ant-design/icons-vue/lib/icons/CopyOutlined'
import NewWindowIcon from '@ant-design/icons-vue/lib/icons/SelectOutlined'
import FrameIcon from '@ant-design/icons-vue/lib/icons/ExpandOutlined'
import LogoutIcon from '@ant-design/icons-vue/lib/icons/ExportOutlined'
import ReloadIcon from '@ant-design/icons-vue/lib/icons/ReloadOutlined'
import BackIcon from '@ant-design/icons-vue/lib/icons/LeftOutlined'

import { AWC_ENV_LIST } from '@/constants/index'

export default {
  name: 'EnterGame',
  components: {
    AntRow,
    AntCol,
    AntButton,
    AntAlert,
    AntTooltip,
    CopyIcon,
    NewWindowIcon,
    FrameIcon,
    LogoutIcon,
    ReloadIcon,
    BackIcon,
  },
  props: {
    selectedSourceLine: Object,
    loginPlayerData: Object,
    requestResponseData: Object,
    onChangeLoginPlayerData: Function,
  },
  setup(props) {
    const router = useRouter()
    if (!props.loginPlayerData.userId) router.push('/testerportal/player-list')

    const showFrame = ref(false)
    const requestResponseData = reactive(props.requestResponseData)
    const alertState = reactive({})

    const onClickCopy = () => navigator.clipboard.writeText(props.loginPlayerData.loginUrl)

    const onClickOpenFrame = () => showFrame.value = !showFrame.value

    const onClickReload = async () => {
      const messageKey = `reload-${Date.now()}`
      antMessage.loading('', { key: messageKey })

      const { selectedSourceLine, loginPlayerData } = props
      const source = selectedSourceLine.source.toUpperCase()

      const url = `${AWC_ENV_LIST[source].domain}/wallet/${loginPlayerData.loginFunction}`
      const { data: { data: awcData } } = await axios.post(`/proxy/${url}`, loginPlayerData.loginRequestData)

      if (awcData) {
        requestResponseData.url = url
        requestResponseData.request = JSON.stringify(loginPlayerData.loginRequestData)
        requestResponseData.response = JSON.stringify(awcData)

        if (awcData.status === '0000') {
          antMessage.success({
            key: messageKey,
            content: 'Re-generate URL successfully',
          })
          props.onChangeLoginPlayerData({ loginUrl: awcData.url })
        } else {
          alertState.type = 'error'
          alertState.message = JSON.stringify(awcData)
        }
      }
    }

    const onClickLogout = async () => {
      const messageKey = `logout-${Date.now()}`
      antMessage.loading('', { key: messageKey })

      const { selectedSourceLine, loginPlayerData } = props
      const { agentId, cert } = props.selectedSourceLine
      const source = selectedSourceLine.source.toUpperCase()

      const url = `${AWC_ENV_LIST[source].domain}/wallet/logout`

      const requestData = {
        cert,
        agentId,
        userIds: loginPlayerData.userId,
      }

      const { data: { data: awcData } } = await axios.post(`/proxy/${url}`, requestData)

      if (awcData) {
        requestResponseData.url = url
        requestResponseData.request = JSON.stringify(requestData)
        requestResponseData.response = JSON.stringify(awcData)

        if (awcData.status === '0000') {
          antMessage.success({
            key: messageKey,
            content: `Player ID: ${loginPlayerData.userId} logout successfully`,
          })

          props.onChangeLoginPlayerData() // empty loginPlayerData
          router.push('/testerportal/player-list')
        } else {
          alertState.type = 'error'
          alertState.message = JSON.stringify(awcData)
        }
      }
    }

    return {
      showFrame,
      alertState,
      onClickCopy,
      onClickOpenFrame,
      onClickReload,
      onClickLogout,
    }
  },
}
</script>

<style scoped>
.iframe-wrapper {
  position: relative;
  height: 100%;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.btn {
  margin: 28px 8px 0px;
}

.iframe-btns {
  position: absolute;
  bottom: 0;
  right: 0;
}

.iframe-btns .btn {
  margin: 0 0 0 8px;
}

.row {
  padding: 64px 24px;
}

.link {
  word-break: break-word;
}
</style>
