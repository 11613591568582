<template>
  <AntRow>

    <AntCol :span="24">
      <!-- <p><b>searchState:</b> {{ searchState }}</p> -->
      <AntRow :gutter="16" class="search-row">
        <AntCol :span="6">
          <AntSelect
            placeholder="Currency Code"
            v-model:value="searchState.currency"
            :options="dataSource.map(({ key }) => ({ label: key, value: key })).sort((a, b) => a.value.localeCompare(b.value))"
            class="search-input"
            showSearch
            allowClear
            filterOption
          />
        </AntCol>

        <AntCol :span="4">
          <AntButton
            type="primary"
            html-type="submit"
            @click="onSearch"
          >
            Search
          </AntButton>
        </AntCol>

      </AntRow>

      <!-- <p><b>editableData:</b> {{ editableData }}</p>
      <p><b>validateState:</b> {{ validateState }}</p> -->
      <AntTable
        :columns="columns"
        :dataSource="displayedDataSource"
        :scroll="{ y: 'calc(100vh - 166px)' }"
        :pagination="{
          pageSize: 50,
          pageSizeOptions: ['50', '100', '300'],
          showSizeChanger: true,
        }"
        bordered
        pageSizeOptions
      >
        <template #rate="{ text, record }">
          <div :title="record.rateLastUpdated">{{ text }}</div>
        </template>

        <template #equivalent="{ text }">
          {{
            text == null
              ? ''
              : text.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 10,
              })
          }}
        </template>

        <template v-for="({ col, rules }) in editableFields" #[col]="{ text, record }" :key="col">
          <AntFormItem
            v-if="editableData[record.key]"
            :id="col"
            :name="col"
            :validateStatus="validateState[record.key]?.[col]?.validateStatus"
            :help="validateState[record.key]?.[col]?.message"
          >
            <AntInput
              v-model:value="editableData[record.key][col]"
              @blur="() => onValidateField(record.key, col, rules)"
            />
          </AntFormItem>
          <div v-else :style="col === 'realCurrency' && !text && { color: 'red'}">
            {{ col === 'realCurrency' && !text ? 'Need to be updated' : text }}
          </div>
        </template>

        <template #operation="{ record }">
          <span v-if="editableData[record.key]">
            <span v-if="Object.values(validateState[record.key]).every(({ validateStatus }) => validateStatus === 'success')">
              <a @click="onSave(record.key, record)">Save</a>
              <AntDivider type="vertical" />
            </span>
            <a @click="onCancel(record.key)">Cancel</a>
          </span>
          <span v-else>
            <a @click="onEdit(record.key)">Edit</a>
          </span>
        </template>
      </AntTable>

    </AntCol>
  </AntRow>
</template>

<script>
import {
  ref,
  reactive,
  watch,
  computed,
} from 'vue'

import {
  Row as AntRow,
  Col as AntCol,
  Table as AntTable,
  Input as AntInput,
  Select as AntSelect,
  Button as AntButton,
  Divider as AntDivider,
  message as antMessage,
  Form as AntForm,
} from 'ant-design-vue'

import { updateCurrency } from '@/api/testerportal'

export default {
  name: 'RatioTable',
  components: {
    AntRow,
    AntCol,
    AntTable,
    AntInput,
    AntSelect,
    AntButton,
    AntDivider,
    AntFormItem: AntForm.Item,
  },
  props: {
    ratioData: Array,
  },
  setup(props) {
    const ratioData = reactive(props.ratioData)
    const editableData = reactive({})

    const searchState = reactive({})

    const columns = [
      {
        title: 'Currency Code',
        dataIndex: 'key',
        width: 60,
        align: 'center',
        sorter: (a, b) => a.key.localeCompare(b.key),
      },
      {
        title: 'Real Currency',
        dataIndex: 'realCurrency',
        align: 'center',
        width: 60,
        sorter: (a, b) => (a.realCurrency || '').localeCompare((b.realCurrency || '')),
        slots: {
          customRender: 'realCurrency',
        },
      },
      {
        title: 'Ratio',
        dataIndex: 'ratio',
        width: 40,
        align: 'center',
        slots: {
          customRender: 'ratio',
        },
      },
      {
        title: 'Ratio with THB',
        dataIndex: 'rate',
        width: 80,
        align: 'right',
        slots: {
          customRender: 'rate',
        },
      },
      {
        title: 'Equivalent in 1000 THB',
        dataIndex: 'equivalentInTHB',
        width: 80,
        align: 'right',
        slots: {
          customRender: 'equivalent',
        },
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        width: 100,
        slots: {
          customRender: 'remark',
        },
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        width: 50,
        slots: {
          customRender: 'operation',
        },
      },
    ]

    const dataSource = computed(() => ratioData.map(({ currency, ...d }) => ({ key: currency, ...d })).sort((a, b) => {
      if (!a.realCurrency && !b.realCurrency) return a.key.localeCompare(b.key)
      if (!a.realCurrency) return -1
      if (!b.realCurrency) return 1
      return a.realCurrency.localeCompare(b.realCurrency)
    }))

    const displayedDataSource = ref(dataSource.value)

    watch(dataSource, (newVal, oldVal) => {
      // console.log('watch dataSource to set displayedDataSource', newVal, oldVal)
      if (!Object.keys(oldVal).length && !!Object.keys(newVal).length) displayedDataSource.value = dataSource.value
    })

    watch(searchState, () => {
      if (Object.values(searchState).every(d => !d || !d.length)) displayedDataSource.value = dataSource.value
    })

    const onSearch = () => {
      displayedDataSource.value = dataSource.value.filter((d) => {
        let matched = true
        if (searchState.currency) matched = matched && d.key.includes(searchState.currency)
        return matched
      })
    }

    const editableFields = [
      {
        col: 'realCurrency',
        rules: [
          {
            required: true,
            message: 'Required',
            trigger: 'blur',
          },
        ],
      },
      {
        col: 'ratio',
        rules: [
          {
            pattern: /^\d+:\d+$/,
            message: 'Invalid format',
            trigger: 'blur',
          },
          {
            required: true,
            message: 'Required',
            trigger: 'blur',
          },
        ],
      },
      {
        col: 'remark',
      },
    ]

    const validateState = reactive({})

    const onValidateField = (currency, field, rules) => {
      if (!rules || !rules.length) return
      const requiredRule = rules.find(({ required }) => required)
      const patternRule = rules.find(({ pattern }) => !!pattern)

      if (!validateState[currency]) validateState[currency] = {}
      if (!validateState[currency][field]) validateState[currency][field] = {}

      if (requiredRule && !editableData[currency][field]) {
        validateState[currency][field].validateStatus = 'error'
        validateState[currency][field].message = requiredRule.message
      } else if (patternRule && !patternRule.pattern.test(editableData[currency][field])) {
        validateState[currency][field].validateStatus = 'error'
        validateState[currency][field].message = patternRule.message
      } else {
        validateState[currency][field].validateStatus = 'success'
        delete validateState[currency][field].message
      }
    }

    const onEdit = (currency) => {
      const data = dataSource.value.find(d => currency === d.key)
      editableData[currency] = Object.keys(data).reduce((acc, key) => {
        if (editableFields.map(({ col }) => col).includes(key)) {
          acc[key] = data[key]
        }
        return acc
      }, {})
      validateState[currency] = {}
    }

    const onSave = async (currency, record) => {
      const messageKey = `updateCurrency-${Date.now()}`
      antMessage.loading('', { key: messageKey })

      const updateData = { currency, ...editableData[currency] }
      const { data, message } = await updateCurrency(updateData)

      if (data) {
        const ratioArr = updateData.ratio.split(':')
        const computredRatio = ratioArr[0] / ratioArr[1]
        const equivalentInTHB = Number((computredRatio * record.rate * 1000).toFixed(3))
        const updateRatioData = {
          ...updateData,
          equivalentInTHB,
        }

        Object.assign(ratioData.find(d => d.currency === currency), updateRatioData)
        Object.assign(dataSource.value.find(d => d.key === currency), updateRatioData)
        displayedDataSource.value = dataSource.value
        delete editableData[currency]

        antMessage.success({
          key: messageKey,
          content: `Update currency: ${currency} successfully`,
        })
      } else {
        antMessage.error({
          key: messageKey,
          duration: 5,
          content: `Failed to update currency: ${message}`,
        })
      }
    }

    const onCancel = (currency) => {
      delete editableData[currency]
      delete validateState[currency]
    }

    return {
      searchState,
      onSearch,
      dataSource,
      displayedDataSource,
      columns,
      editableData,
      editableFields,
      onEdit,
      onSave,
      onCancel,
      validateState,
      onValidateField,
    }
  },
}
</script>

<style scoped>
.search-row {
  margin-bottom: 28px;
}

.search-input {
  width: 100%;
}

.ant-form-item {
  margin-bottom: 0;
  text-align: left;
}
</style>
